.ReactModal__Overlay {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 1;
}

@keyframes rotateAnimation {
  from {
    opacity: 0.5;
    transform: rotateZ(-3deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0);
  }
}

@keyframes rotateAnimationInFromR {
  from {
    opacity: 0.5;
    transform: rotateZ(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0);
  }
}

@keyframes rotateAnimationInFromL {
  from {
    opacity: 0.5;
    transform: rotateZ(10deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0);
  }
}

@keyframes rotateAnimationOutToR {
  from {
    opacity: 1;
    transform: rotateZ(0);
  }

  to {
    opacity: 0;
    transform: rotateZ(-10deg);
  }
}

@keyframes rotateAnimationOutToL {
  from {
    opacity: 1;
    transform: rotateZ(0);
  }

  to {
    opacity: 0;
    transform: rotateZ(10deg);
  }
}

/* PC */
@keyframes rotateAnimationPC {
  from {
    opacity: 0.5;
    transform: rotateZ(-6deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0);
  }
}

@keyframes rotateAnimationInFromRPC {
  from {
    opacity: 0.5;
    transform: rotateZ(-20deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0);
  }
}

@keyframes rotateAnimationInFromLPC {
  from {
    opacity: 0.5;
    transform: rotateZ(20deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0);
  }
}

@keyframes rotateAnimationOutToRPC {
  from {
    opacity: 1;
    transform: rotateZ(0);
  }

  to {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
}

@keyframes rotateAnimationOutToLPC {
  from {
    opacity: 1;
    transform: rotateZ(0);
  }

  to {
    opacity: 0;
    transform: rotateZ(20deg);
  }
}