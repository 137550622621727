.swiper-pagination-bullet {
  height: 5px;
  width: 5px;
  background-color: rgba(233, 231, 220, 0);
  border: 0.4px solid #222222;
}

.swiper-pagination-bullet-active {
  height: 5px;
  width: 5px;
  background-color: #222222;
  border: 0.00px solid #222222;
}

.swiper-pagination-bullet {
  opacity: 1;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, calc(1.3% - 2.5px));
}