body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.listhint-title {
  margin: 0;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 600;
  font-style: normal;
  color: #222222
}

.listhint-about {
  margin: 0;
  font-family: "Marcellus", serif;
  color: #222222
}

.listhint-year {
  margin: 0;
  font-family: "Marcellus", serif;
  font-weight: 400;
  font-style: normal;
  color: #222222;
}

.popup-title {
  margin: 0;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 600;
  font-style: normal;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.popup-about {
  margin: 0;
  font-style: normal;
  font-family: "Marcellus", serif;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.popup-year {
  margin: 0;
  font-style: normal;
  font-family: "Marcellus", serif;
}

.popup-headline {
  margin: 0;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 600;
  font-style: normal;
  white-space: pre;
  writing-mode: vertical-rl;
}

.popup-body {
  margin: 0;
  font-family: "yu-gothic-pr6n", sans-serif;
  font-style: normal;
  white-space: pre-wrap;
}

.popup-body-subheadline {
  margin: 0;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 600;
  font-style: normal;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
}


/* 以下は未使用 */

.popup-scroll {
  margin: 0;
  font-family: "Marcellus", serif;
  font-style: normal;
  writing-mode: vertical-lr;
  font-size: 10pt;
  transform: translate(-50%,-50%);
}

.scrolldown-circle {
  font-size: 10pt;
  height: 100px;
  transform: translate(0%,-15%);
}

/* 丸の描写 */
.scrolldown-circle:before {
  content: "";
  /*描画位置*/
  position: absolute;
  bottom:0;
  left:-2.4px;
  /*丸の形状*/
  width:6px;
  height:6px;
  border-radius: 50%;
  background:#484746;
  z-index: 1;
  /*丸の動きn秒かけて透過し、永遠にループ*/
  animation:
    circlemove 2.5s ease-in-out infinite,
    cirlemovehide 2.5s ease-out infinite;
}

/*下からの距離が変化して丸の全体が上から下に動く*/
@keyframes circlemove{
  0%{bottom:95px;}
  100%{bottom:-5px;}
}

/*上から下にかけて丸が透過→不透明→透過する*/
@keyframes cirlemovehide{
  0%{opacity:0}
  50%{opacity:1;}
  80%{opacity:0.9;}
  100%{opacity:0;}
}

/* 固定線の描画 */
.scrolldown-circle:after{
  content:"";
    /*描画位置*/
  position: absolute;
  bottom:0;
  left:0;
    /*線の形状*/
  width:1.3px;
  height: 100px;
  background:#bdbdbc;
}

/* 動く線の描写 */
.scrolldown-line:before{
  content:"";
    /*描画位置*/
  position: absolute;
  top:-15px;
  left:0;
    /*線の形状*/
  width:1.5px;
  height: 0px;
  background:#484746;
  animation:
    linemove 2.5s ease-in-out infinite,
    linehide 2.5s ease-out infinite;
}

@keyframes linemove{
  0%{height:0px;}
  100%{height:100px;}
}

@keyframes linehide{
  0%{opacity:0}
  50%{opacity:1;}
  80%{opacity:0.9;}
  100%{opacity:0;}
}
