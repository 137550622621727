@keyframes mouseClickAnimation {
  0% {
    stroke-dasharray: 230;
    transform: scale(1);
  }
  20% {
    stroke-dasharray: 150;
    transform: scale(0);
  }
  40% {
    stroke-dasharray: 150;
    transform: scale(1);
  }
  100% {
    stroke-dasharray: 230;
    transform: scale(1);
  }
}

@keyframes mouseAppearanceAnimation {
  from {
    opacity: 1;
    transform: scale(0.0);
  }

  to {
    opacity: 0.3;
    transform: scale(1);
  }
}